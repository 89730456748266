import axios, { AxiosInstance } from 'axios';
import { Configuration, GeneralApi } from '@7c7/backend-api-client';
import { apiUrl$ } from './base-conf.resource';

export const apiUnauthorizedConfiguration$: Promise<{ apiConfiguration: Configuration; defaultAxios: AxiosInstance }> =
    (async () => ({
        apiConfiguration: new Configuration({ basePath: await apiUrl$ }),
        defaultAxios: axios.create({ baseURL: await apiUrl$ }),
    }))();
export const backendUnauthorizedApiResource$ = (async () => {
    const { defaultAxios, apiConfiguration } = await apiUnauthorizedConfiguration$;
    return {
        generalApi: new GeneralApi(apiConfiguration, undefined, defaultAxios),
    };
})();
