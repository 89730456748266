import { PropNumericLimitDto, PropResultDto, StepResultDto } from '@7c7/backend-api-client';

export interface IStepValue {
    data?: PropResultDto;
    numericData?: PropNumericLimitDto;
}

export function getValuesFromStep(stepResult: StepResultDto): IStepValue[] {
    return stepResult?.propResults?.map((x) => {
        const { propNumericLimits } = x;
        const numericData = propNumericLimits?.[0];
        return { data: x, numericData };
    });
}

export function parseNumericLimit(numericLimit: PropNumericLimitDto) {
    const { compOperator, lowLimit, highLimit } = numericLimit;
    if (compOperator === 'EQ') return `== ${lowLimit}`;
    if (compOperator === 'NEQ') return `!= ${lowLimit}`;
    if (compOperator === 'GT') return `> ${lowLimit}`;
    if (compOperator === 'LT') return `< ${lowLimit}`;
    if (compOperator === 'GE') return `>= ${lowLimit}`;
    if (compOperator === 'LE') return `<= ${lowLimit}`;
    if (compOperator === 'GTLT') return `${lowLimit} < X < ${highLimit}`;
    if (compOperator === 'GELE') return `${lowLimit} <= X <= ${highLimit}`;
    if (compOperator === 'GELT') return `${lowLimit} <= X < ${highLimit}`;
    if (compOperator === 'GTLE') return `${lowLimit} < X <= ${highLimit}`;
    if (compOperator === 'LTGT') return `X < ${lowLimit} OR ${highLimit} < X`;
    if (compOperator === 'LEGE') return `X <= ${lowLimit} OR ${highLimit} <= X`;
    if (compOperator === 'LEGT') return `X <= ${lowLimit} OR ${highLimit} < X`;
    if (compOperator === 'LTGE') return `X < ${lowLimit} OR ${highLimit} <= X`;
    return compOperator;
}
