export const statusColor: {
    [status: string]: 'success' | 'error' | 'inherit' | 'grey' | 'primary' | 'secondary' | 'info' | 'warning';
} = {
    Passed: 'success',
    Done: 'success',
    Error: 'error',
    Skipped: 'primary',
    Terminated: 'warning',
    Aborted: 'error',
    Failed: 'error',
};
