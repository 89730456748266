import { PropResultDto, StepResultDto } from '@7c7/backend-api-client';
import Label from 'src/components/label';
import { IStepValue } from '../helpers/step-value.helper';
import TestResultNumericLimitValue from './test-result-numeric-limit.value';

const parseData = (result?: PropResultDto): string => {
    if (!result) return '';
    if (result.typeName === 'Boolean') return result.data === '1' ? 'true' : 'false';
    return result.data ?? '/';
};
export default function TestResultValue({
    stepValues,
    stepResult,
}: {
    stepResult: StepResultDto;
    stepValues: IStepValue[];
}) {
    if (stepResult.stepType === 'StringValueTest' && stepValues.length === 4) {
        const firstValue = stepValues.find((x) => x.data?.path === 'String');
        const secondValue = stepValues.find((x) => x.data?.path === 'Limits.String');

        return (
            <>
                <span style={{ marginRight: '10px' }}>{`'${firstValue?.data?.data}'`}</span>
                <Label variant='soft' color='warning'>
                    {` == '${secondValue?.data?.data}'`}
                </Label>
            </>
        );
    }
    if (stepResult.stepType === 'PassFailTest' && stepValues.length === 1) {
        return <>{parseData(stepValues[0].data)}</>;
    }
    if (['MessagePopup', 'Action'].includes(stepResult.stepType ?? '')) {
        const value = stepValues.reduce((t, v) => `${t}${t === '' ? '' : ' > '}${v.data?.path} ${v.data?.data}`, '');
        return <>{value}</>;
    }
    if (stepResult.stepType === 'NumericLimitTest') {
        const filteredValues = stepValues.filter((x) => x.data?.path !== '[Return Value [Out]]');
        if (filteredValues.length === 1 && filteredValues[0].data?.path === 'Numeric') {
            return (
                <TestResultNumericLimitValue
                    prop={filteredValues[0].data as PropResultDto}
                    numericLimit={filteredValues[0].numericData}
                />
            );
        }
        return (
            <div>
                {stepValues.map((value) => (
                    <div key={value.data?.id}>
                        <div>
                            {value.data?.id} {value.data?.path} {parseData(value.data)}
                        </div>
                        {value?.numericData ? (
                            <div style={{ color: 'yellow' }}>
                                [ {value.numericData.lowLimit} | {value.numericData.highLimit} ]
                            </div>
                        ) : undefined}
                    </div>
                ))}
            </div>
        );
    }
    return (
        <div>
            {stepValues.map((value) => (
                <div key={value.data?.id}>
                    <div>
                        {value.data?.id} {value.data?.path} {parseData(value.data)}
                    </div>
                    {value?.numericData ? (
                        <div style={{ color: 'yellow' }}>
                            [ {value.numericData.lowLimit} | {value.numericData.highLimit} ]
                        </div>
                    ) : undefined}
                </div>
            ))}
        </div>
    );
}
