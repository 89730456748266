// @mui
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';
// types
import Iconify from 'src/components/iconify';
import { HistoryTableFilter } from './types/history-table-filter';
// components

// ----------------------------------------------------------------------

type Props = StackProps & {
    filters: HistoryTableFilter;
    onFilters: (value: HistoryTableFilter) => void;
    //
    onResetFilters: VoidFunction;
    //
};

function canReset(filters: HistoryTableFilter) {
    return !filters.cleanedData || filters.stepName || filters.filteredStepNames.length;
}

export default function TestResultsDetailTableFiltersResult({
    filters,
    onFilters,
    //
    onResetFilters,
    //
    ...other
}: Props) {
    const handleRemoveStepName = (inputValue: string) => {
        const newValue = filters.filteredStepNames.filter((item) => item !== inputValue);
        onFilters({ ...filters, filteredStepNames: newValue });
    };

    return (
        <Stack spacing={1.5} {...other}>
            <Stack flexGrow={1} spacing={1} direction='row' flexWrap='wrap' alignItems='center'>
                {!!filters.filteredStepNames.length && (
                    <Block label='Filtered Steps:'>
                        {filters.filteredStepNames.map((item) => (
                            <Chip key={item} label={item} size='small' onDelete={() => handleRemoveStepName(item)} />
                        ))}
                    </Block>
                )}

                {canReset(filters) ? (
                    <Button
                        color='error'
                        onClick={onResetFilters}
                        startIcon={<Iconify icon='solar:trash-bin-trash-bold' />}
                    >
                        Clear
                    </Button>
                ) : undefined}
            </Stack>
        </Stack>
    );
}

// ----------------------------------------------------------------------

type BlockProps = StackProps & {
    label: string;
};

function Block({ label, children, sx, ...other }: BlockProps) {
    return (
        <Stack
            component={Paper}
            variant='outlined'
            spacing={1}
            direction='row'
            sx={{
                p: 1,
                borderRadius: 1,
                overflow: 'hidden',
                borderStyle: 'dashed',
                ...sx,
            }}
            {...other}
        >
            <Box component='span' sx={{ typography: 'subtitle2' }}>
                {label}
            </Box>

            <Stack spacing={1} direction='row' flexWrap='wrap'>
                {children}
            </Stack>
        </Stack>
    );
}
