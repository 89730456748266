// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { Link } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// components
import { UutResultDto } from '@7c7/backend-api-client';
import { visualizeDuration } from '../helpers/test-result-duration.helper';

// ----------------------------------------------------------------------

type Props = {
    uutResult: UutResultDto;
};

export default function OrderDetailsInfo({ uutResult }: Props) {
    const renderCustomer = (
        <>
            <CardHeader title='Device info' />
            <Stack direction='row' sx={{ p: 3 }}>
                <Stack spacing={0.5} alignItems='flex-start' sx={{ typography: 'body2' }}>
                    <Typography variant='subtitle2'>{uutResult.uutSerialNumber}</Typography>
                    <Box sx={{ color: 'text.secondary' }}>{uutResult.partNumber}</Box>
                </Stack>
            </Stack>
        </>
    );

    const renderTest = (
        <>
            <CardHeader title='Test' />
            <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
                <Stack direction='row' alignItems='center'>
                    <Box component='span' sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
                        Test id
                    </Box>
                    {uutResult.id}
                </Stack>
                <Stack direction='row' alignItems='center'>
                    <Box component='span' sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
                        Duration
                    </Box>
                    {visualizeDuration(uutResult.executionTime)}
                </Stack>
                <Stack direction='row' alignItems='center'>
                    <Box component='span' sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
                        Status
                    </Box>
                    {uutResult.status}
                </Stack>
                <Stack direction='row' alignItems='center'>
                    <Box component='span' sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
                        WorkStation
                    </Box>
                    {uutResult.stationId}
                </Stack>
            </Stack>
        </>
    );
    const renderMeta = (
        <>
            <CardHeader title='Meta' />
            <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
                {uutResult.metaData?.map((metaData) => (
                    <Stack direction='row' alignItems='center'>
                        <Box component='span' sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
                            {metaData.metaDefinition?.flagName}
                        </Box>
                        {metaData.value ? (
                            <Link
                                href={paths.ate.test_results.rootSearch(metaData.value)}
                                component={RouterLink}
                                color='white'
                            >
                                {metaData.value}
                            </Link>
                        ) : undefined}
                    </Stack>
                ))}
            </Stack>
        </>
    );

    return (
        <Card>
            {renderCustomer}

            <Divider sx={{ borderStyle: 'dashed' }} />

            {renderTest}

            <Divider sx={{ borderStyle: 'dashed' }} />

            {renderMeta}
        </Card>
    );
}
