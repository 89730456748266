import axios, { AxiosInstance } from 'axios';
import { UserApi, UUTResultApi, GeneralApi, Configuration, MetaDefinitionApi } from '@7c7/backend-api-client';
import { apiUrl$ } from './base-conf.resource';
import { getAccessToken } from './oidc.resource';

export const apiAuthorizedConfiguration$: Promise<{ apiConfiguration: Configuration; defaultAxios: AxiosInstance }> =
    (async () => ({
        apiConfiguration: new Configuration({ basePath: await apiUrl$, accessToken: getAccessToken }),
        defaultAxios: axios.create({ baseURL: await apiUrl$ }),
    }))();
export const backendApiResource$ = (async () => {
    const { defaultAxios, apiConfiguration } = await apiAuthorizedConfiguration$;
    return {
        uutResultApi: new UUTResultApi(apiConfiguration, undefined, defaultAxios),
        userApi: new UserApi(apiConfiguration, undefined, defaultAxios),
        generalApi: new GeneralApi(apiConfiguration, undefined, defaultAxios),
        metaDefinitionApi: new MetaDefinitionApi(apiConfiguration, undefined, defaultAxios),
    };
})();
