import { StepResultDto } from '@7c7/backend-api-client';
import { LabelColor } from 'src/components/label';

export type StepStatesCount = {
    [status: string]: {
        count: number;
        status: string;
    };
};

const unnecessaryStepNames = ['End', 'Wait', 'Do While', 'wait_for_test_to_end'];
export function getCleanedStepResults(stepResults: StepResultDto[]): StepResultDto[] {
    return stepResults.filter((x) => !unnecessaryStepNames.includes(x?.stepName ?? ''));
}

export function countStepStates(stepResults: StepResultDto[]): StepStatesCount {
    const output: StepStatesCount = {};
    stepResults.forEach((stepResult) => {
        let { status } = stepResult;
        if (!status) status = 'Unknown';
        if (!output[status]) output[status] = { count: 0, status };
        output[status].count += 1;
    });
    return output;
}

export function getStateColor(state: string): LabelColor {
    if (['Passed', 'Done'].includes(state)) return 'success';
    if (['Failed', 'Error'].includes(state)) return 'error';
    return 'warning';
}
