import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { useSettingsContext } from '../settings/context/settings-context';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
    disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, sx, ...other }, ref) => {
    const settings = useSettingsContext();

    const imgSizePx = settings.themeLayout === 'vertical' ? 215 : 55;

    const logo = (
        <Box
            ref={ref}
            component='img'
            src='/logo/7c7-logo.png'
            sx={{
                display: 'inline-flex',
                ...sx,
                width: imgSizePx,
                height: imgSizePx,
            }}
            {...other}
        />
    );

    if (disabledLink) {
        return logo;
    }

    return (
        <Link component={RouterLink} href='/' sx={{ display: 'contents' }}>
            {logo}
        </Link>
    );
});

export default Logo;
