import { Configuration, ProtocolMode, BrowserCacheLocation } from '@azure/msal-browser';
import { webConfiguration$ } from './web-conf.resource';

export const msalConfig$: Promise<Configuration> = (async function () {
    const conf = await webConfiguration$;
    const msalConfig: Configuration = {
        auth: {
            clientId: 'ate',
            authority: conf.keycloakUrl,
            knownAuthorities: [conf.keycloakUrl.replace(/https?:\/\/([^/]+)\/.*/, '$1')],
            protocolMode: ProtocolMode.OIDC,
            redirectUri: `${window.location.origin}/#/callback`,
            postLogoutRedirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
    };
    return msalConfig;
})();
