import { PropNumericLimitDto, PropResultDto } from '@7c7/backend-api-client';
import Label from 'src/components/label';
import { parseNumericLimit } from '../helpers/step-value.helper';

type Props = {
    prop?: PropResultDto;
    numericLimit?: PropNumericLimitDto;
};

export default function TestResultNumericLimitValue({ prop, numericLimit }: Props) {
    if (!prop) return <>/</>;
    let data = parseFloat(prop.data ?? '');
    const rounded = Math.round(data * 10000) / 10000;
    if (rounded !== data && Math.abs(rounded - data) < 0.00001) data = rounded;
    if (!numericLimit) return <>{data}</>;
    return (
        <>
            <span style={{ marginRight: '10px' }}>{data}</span>
            <Label variant='soft' color='warning'>
                {parseNumericLimit(numericLimit)}
            </Label>
        </>
    );
}
