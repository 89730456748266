import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { ateRoutes } from './ate';

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: <Navigate to={PATH_AFTER_LOGIN} replace />,
        },

        // Auth routes
        // ...authRoutes,

        // Ate routes
        ...ateRoutes,

        // Main routes
        ...mainRoutes,

        // No match 404
        { path: '*', element: <Navigate to='/404' replace /> },
    ]);
}
