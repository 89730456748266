// ----------------------------------------------------------------------

const ROOTS = {
    AUTH: '/auth',
    ATE: '/ate',
};

// ----------------------------------------------------------------------

export const paths = {
    page403: '/403',
    page404: '/404',
    // AUTH
    auth: {
        jwt: {
            login: `${ROOTS.AUTH}/jwt/login`,
            register: `${ROOTS.AUTH}/jwt/register`,
        },
    },
    // DASHBOARD
    ate: {
        app: `${ROOTS.ATE}/dashboard`,
        test_results: {
            root: `${ROOTS.ATE}/test_results`,
            rootSearch: (search: string) => `${ROOTS.ATE}/test_results?search=${search}`,
            details: (id: number) => `${ROOTS.ATE}/test_results/${id}`,
        },
        group: {
            root: `${ROOTS.ATE}/group`,
            five: `${ROOTS.ATE}/group/five`,
            six: `${ROOTS.ATE}/group/six`,
        },
    },
};
