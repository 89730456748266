import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
// components
import { NavSectionHorizontal } from 'src/components/nav-section';
//
import { HEADER } from '../config-layout';
import { useNavData } from './config-navigation';
import { HeaderShadow } from '../_common';
import { useAuthContext } from '../../auth/hooks';

// ----------------------------------------------------------------------

function NavHorizontal() {
    const theme = useTheme();

    const { user } = useAuthContext();

    const navData = useNavData();

    return (
        <AppBar
            component='nav'
            sx={{
                top: HEADER.H_DESKTOP_OFFSET,
            }}
        >
            <Toolbar
                sx={{
                    ...bgBlur({
                        color: theme.palette.background.default,
                    }),
                }}
            >
                <NavSectionHorizontal
                    data={navData}
                    config={{
                        currentRole: user?.role || 'admin',
                    }}
                />
            </Toolbar>

            <HeaderShadow />
        </AppBar>
    );
}

export default memo(NavHorizontal);
