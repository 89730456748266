// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from './components/snackbar';

// ----------------------------------------------------------------------

export default function App() {
    useScrollToTop();
    // #2e6e51
    return (
        <AuthProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider
                    defaultSettings={{
                        themeMode: 'dark', // 'light' | 'dark'
                        themeDirection: 'ltr', //  'rtl' | 'ltr'
                        themeContrast: 'default', // 'default' | 'bold'
                        themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                        themeColorPresets: '7c7', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                        themeStretch: false,
                    }}
                >
                    <ThemeProvider>
                        <SnackbarProvider>
                            <MotionLazy>
                                <SettingsDrawer />
                                <ProgressBar />
                                <AuthConsumer>
                                    <Router />
                                </AuthConsumer>
                            </MotionLazy>
                        </SnackbarProvider>
                    </ThemeProvider>
                </SettingsProvider>
            </LocalizationProvider>
        </AuthProvider>
    );
}
