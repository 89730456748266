import { UutResultDto } from '@7c7/backend-api-client';
// @mui
import { Link } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
// utils
import { fDateTime } from 'src/utils/format-time';
// ----------------------------------------------------------------------
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import { statusColor } from '../helpers/status-color.helper';

type Props = {
    uutResults: UutResultDto[];
};

export default function TestResultDetailsTestHistory({ uutResults }: Props) {
    const renderTimeline = (
        <Timeline
            sx={{
                p: 0,
                m: 0,
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                },
            }}
        >
            {uutResults?.map((item, index) => {
                const color = statusColor[item.status ?? ''] ?? 'grey';

                const lastTimeline = index === uutResults.length - 1;

                return (
                    <TimelineItem key={item.id}>
                        <TimelineSeparator>
                            <TimelineDot color={color} />
                            {lastTimeline ? null : <TimelineConnector />}
                        </TimelineSeparator>

                        <TimelineContent>
                            <Link
                                href={paths.ate.test_results.details(item.id)}
                                component={RouterLink}
                                color='inherit'
                                sx={{ typography: 'subtitle2' }}
                            >
                                {item.id}
                            </Link>

                            <Box sx={{ color: 'text.disabled', typography: 'caption', mt: 0.5 }}>
                                <span>{item.status}</span>
                                <span style={{ marginLeft: '15px' }}>
                                    {item.startDateTime ? fDateTime(item.startDateTime, 'dd/MM/yyyy HH:mm') : '/'}
                                </span>
                            </Box>
                        </TimelineContent>
                    </TimelineItem>
                );
            })}
        </Timeline>
    );

    return (
        <Card>
            <CardHeader title='History' />
            <Stack
                spacing={3}
                alignItems={{ md: 'flex-start' }}
                direction={{ xs: 'column-reverse', md: 'row' }}
                sx={{ p: 3 }}
            >
                {renderTimeline}
            </Stack>
        </Card>
    );
}
