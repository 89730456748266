import { Helmet } from 'react-helmet-async';
// sections
import TestResultDetailView from 'src/sections/test-results/view/test-result-detail-view';

// ----------------------------------------------------------------------

export default function TestResultsDetailPage() {
    return (
        <>
            <Helmet>
                <title> Test Results</title>
            </Helmet>

            <TestResultDetailView />
        </>
    );
}
