import { useEffect, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
// types
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { HistoryTableFilter } from './types/history-table-filter';
// ----------------------------------------------------------------------

type Props = {
    filters: HistoryTableFilter;
    filterCleanedData: (value: boolean) => void;
    filterStepName: (value: string) => void;
    lastClearedMs: number;
    downloadExport: () => void;
};

export default function TestResultsDetailTableToolbar({
    filters,
    filterCleanedData,
    filterStepName,
    downloadExport,
    lastClearedMs,
}: Props) {
    const popover = usePopover();
    const [stepName, setStepName] = useState(filters.stepName);

    useEffect(() => setStepName(''), [lastClearedMs]);

    useEffect(() => {
        if (stepName === filters.stepName) return () => null;
        const timer = setTimeout(() => filterStepName(stepName), 300);

        return () => {
            clearTimeout(timer);
        };
    }, [stepName, filters.stepName, filterStepName]);

    return (
        <>
            <Stack
                spacing={2}
                alignItems={{ xs: 'flex-end', md: 'center' }}
                direction={{
                    xs: 'column',
                    md: 'row',
                }}
                sx={{
                    p: 2.5,
                    pr: { xs: 2.5, md: 1 },
                }}
            >
                <Stack direction='row' alignItems='center' spacing={2} flexGrow={1} sx={{ width: 1 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disableRipple
                                size='small'
                                checked={filters.cleanedData}
                                onChange={(_event, value) => filterCleanedData(value)}
                            />
                        }
                        label='Cleaned Data'
                    />
                    <TextField
                        value={stepName}
                        onChange={(e) => setStepName(e.target.value)}
                        placeholder='Search step name...'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                <IconButton onClick={popover.onOpen}>
                    <Iconify icon='eva:more-vertical-fill' />
                </IconButton>
            </Stack>

            <CustomPopover open={popover.open} onClose={popover.onClose} arrow='right-top' sx={{ width: 140 }}>
                <MenuItem
                    onClick={() => {
                        downloadExport();
                        popover.onClose();
                    }}
                >
                    <Iconify icon='solar:export-bold' />
                    Export
                </MenuItem>
            </CustomPopover>
        </>
    );
}
