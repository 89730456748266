import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);
export const visualizeDuration = (durationInSeconds?: number) => {
    if (!durationInSeconds) return '/';
    const executionDuration = dayjs.duration(durationInSeconds, 'seconds');
    const totalDays = Math.floor(executionDuration.asDays()) ?? 0;
    const totalMs = Math.floor(executionDuration.get('milliseconds')) ?? 0;
    const output = executionDuration.format(`HH [hours] mm [min] ss [sec] [${totalMs} ms] `);
    return `${totalDays ? `${totalDays} days` : ''}${output.replace('00 hours', '').replace('00 min', '')}`;
};
