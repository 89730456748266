// @mui
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import { Chip } from '@mui/material';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { MetaDefinitionDto, StepResultDto } from '@7c7/backend-api-client';
import { visualizeDuration } from '../helpers/test-result-duration.helper';
import { statusColor } from '../helpers/status-color.helper';
import { getValuesFromStep } from '../helpers/step-value.helper';
import TestResultValue from './test-result-value';

type Props = {
    row: StepResultDto;
    metaDefinition?: MetaDefinitionDto;
    filterStepName: () => void;
    upsertMetaFlagClicked: (metaDefinition?: MetaDefinitionDto) => void;
};

export default function TestResultDetailTableRow({
    row,
    metaDefinition,
    filterStepName,
    upsertMetaFlagClicked,
}: Props) {
    const popover = usePopover();
    const errorPopover = usePopover();

    const canGetMetaTag = !!(row?.propResults ?? []).find(
        (x) => x.name && ['Return Value [Out]', 'Response'].includes(x.name),
    );

    return (
        <>
            <TableRow hover>
                <TableCell>
                    <ListItemText
                        primary={`${row.orderNumber}`}
                        primaryTypographyProps={{ typography: 'body2', noWrap: true }}
                    />
                </TableCell>
                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography variant='body2' noWrap>
                                {row.stepName}
                            </Typography>
                        }
                        secondary={
                            <Typography variant='body2' noWrap color='rgb(99, 115, 129)'>
                                {row.stepType}
                            </Typography>
                        }
                    />
                </TableCell>

                <TableCell>
                    <Label
                        onClick={(event) => {
                            if (row.errorMessage) errorPopover.onOpen(event);
                        }}
                        style={{ cursor: row.status === 'Error' ? 'pointer' : 'default' }}
                        variant='soft'
                        color={(statusColor[row.status ?? ''] as any) ?? ('grey' as any)}
                    >
                        {row.status}
                    </Label>
                </TableCell>

                <TableCell>
                    <ListItemText
                        primary={visualizeDuration(row.totalTime ?? 0)}
                        primaryTypographyProps={{ typography: 'body2', noWrap: true }}
                    />
                </TableCell>

                <TableCell align='center'>
                    <TestResultValue stepResult={row} stepValues={getValuesFromStep(row)} />
                </TableCell>

                <TableCell align='right' sx={{ px: 1 }}>
                    {metaDefinition ? <Chip label={metaDefinition.flagName} size='small' /> : undefined}
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon='eva:more-vertical-fill' />
                    </IconButton>
                </TableCell>
            </TableRow>
            <CustomPopover
                open={errorPopover.open}
                onClose={errorPopover.onClose}
                arrow='bottom-center'
                sx={{ minWidth: '400px', maxWidth: '800px' }}
            >
                <div style={{ padding: '15px' }}>
                    <>{row.errorCode}</>
                    <br />
                    <>{row.errorMessage}</>
                </div>
            </CustomPopover>
            <CustomPopover open={popover.open} onClose={popover.onClose} arrow='right-top' sx={{ width: 160 }}>
                <MenuItem
                    onClick={() => {
                        filterStepName();
                        popover.onClose();
                    }}
                >
                    <Iconify icon='solar:trash-bin-trash-bold' />
                    Exclude
                </MenuItem>
                {row.errorMessage ? (
                    <MenuItem
                        onClick={(event) => {
                            popover.onClose();
                            errorPopover.onOpen(event);
                        }}
                    >
                        <Iconify icon='solar:eye-bold' />
                        Show error
                    </MenuItem>
                ) : undefined}

                {canGetMetaTag ? (
                    <MenuItem
                        onClick={() => {
                            popover.onClose();
                            upsertMetaFlagClicked(metaDefinition);
                        }}
                    >
                        <Iconify icon='solar:tag-bold' />
                        Meta Tag
                    </MenuItem>
                ) : undefined}
            </CustomPopover>
        </>
    );
}
