import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import AteLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import TestResultsDetailPage from '../../pages/ate/test-results/detail';

// ----------------------------------------------------------------------

const AppPage = lazy(() => import('src/pages/ate/dashboard'));
const TestResultsOverViewPage = lazy(() => import('src/pages/ate/test-results/overview'));

// ----------------------------------------------------------------------

export const ateRoutes = [
    {
        path: 'ate',
        element: (
            // <AuthGuard>
            <AteLayout>
                <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                </Suspense>
            </AteLayout>
            // </AuthGuard>
        ),
        children: [
            { path: 'dashboard', element: <AppPage /> },
            {
                path: 'test_results',
                children: [
                    { element: <TestResultsOverViewPage />, index: true },
                    { path: ':id', element: <TestResultsDetailPage /> },
                ],
            },
        ],
    },
];
