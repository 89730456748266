import { useEffect, useState } from 'react';
import { MetaDefinitionDto, StepResultDto, UutResultDto } from '@7c7/backend-api-client';
import { backendApiResource$ } from 'src/resources/backend-api.resource';
import { downloadFileFromApi } from 'src/utils/axios';
import { AxiosResponse } from 'axios';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
// cmponents
import Label, { LabelColor } from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
import { TableNoData, useTable } from 'src/components/table';
import { countStepStates, getCleanedStepResults, getStateColor } from '../helpers/uut-result.helper';
import TestResultDetailTableRow from './table-row';
import { HistoryTableFilter } from './types/history-table-filter';
import TestResultsDetailTableFiltersResult from './table-filters-result';
import TestResultsDetailTableToolbar from './table-toolbar';
// ----------------------------------------------------------------------

type Props = {
    uutResult: UutResultDto;
    metaDefinitions: MetaDefinitionDto[];
    upsertMetaData: (stepName: string, metaDefinition?: MetaDefinitionDto) => void;
};
type HistoryTabHead = { value: string; label: string; color: LabelColor; count: number };

const TABLE_HEAD: {
    id: string;
    label?: string;
    align?: 'left' | 'right' | 'center' | 'inherit' | 'justify';
}[] = [
    { id: 'stepOrderNumber', label: 'Step' },
    { id: 'uutSerialNumber', label: 'Step Name' },
    { id: 'status', label: 'Status' },
    { id: 'executionTime', label: 'Duration' },
    { id: 'stationId', label: 'Value', align: 'center' },
    { id: '' },
];
const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
} as const;

const defaultFilters: HistoryTableFilter = {
    cleanedData: true,
    status: '',
    stepName: '',
    filteredStepNames: [],
};

export default function TestResultDetailsHistory({ uutResult, upsertMetaData, metaDefinitions }: Props) {
    const theme = useTheme();
    const [tabs, setTabs] = useState<HistoryTabHead[]>([]);
    const [filters, setFilters] = useState<HistoryTableFilter>(defaultFilters);
    const [stepResults, setStepResults] = useState<StepResultDto[]>([]);
    const [tableData, setTableData] = useState<StepResultDto[]>([]);
    const [lastClearedMs, setLastClearedMs] = useState<number>(Date.now());

    useEffect(() => {
        let usedStepResults = uutResult?.stepResults ?? [];
        if (filters.cleanedData) usedStepResults = getCleanedStepResults(usedStepResults);

        setStepResults(usedStepResults);
        const calculatedCounts = countStepStates(usedStepResults);
        setTabs([
            { value: '', label: 'All', color: 'default', count: usedStepResults.length },
            ...Object.values(calculatedCounts)
                .sort((a, b) => (a.status < b.status ? -1 : 1))
                .map(({ status, count }) => ({
                    value: status,
                    label: status,
                    color: getStateColor(status),
                    count,
                })),
        ]);
    }, [uutResult, filters.cleanedData]);

    useEffect(() => {
        const { status, stepName, filteredStepNames } = filters;
        let filteredData = stepResults ?? [];
        if (filteredStepNames?.length)
            filteredData = filteredData.filter((x) => !filteredStepNames.includes(x.stepName ?? ''));
        if (status !== '') filteredData = filteredData.filter((x) => x.status === status);

        if (stepName !== '') {
            const regex = new RegExp(stepName, 'i');
            filteredData = filteredData.filter((x) => x.stepName?.match(regex));
        }
        setTableData(filteredData);
    }, [filters, stepResults]);

    const table = useTable({ defaultOrderBy: 'startDateTime', defaultOrder: 'desc' });

    const notFound = !uutResult?.stepResults?.length;

    const downloadExport = async () => {
        const response = (await (
            await backendApiResource$
        ).uutResultApi.uutResultControllerExportUutResult(uutResult.id, {
            responseType: 'blob',
        })) as AxiosResponse;
        await downloadFileFromApi(response);
    };

    return (
        <Card>
            <Tabs
                value={filters.status}
                onChange={(_event: React.SyntheticEvent, newValue: string) => {
                    if (filters.status === newValue) return;
                    setFilters({
                        ...filters,
                        status: newValue,
                    });
                }}
                sx={{
                    px: 2.5,
                    boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
            >
                {tabs.map((tab) => (
                    <Tab
                        key={tab.value}
                        value={tab.value}
                        label={tab.label}
                        iconPosition='end'
                        icon={
                            <Label
                                variant={
                                    ((tab.value === undefined || tab.value === filters.status) && 'filled') || 'soft'
                                }
                                color={tab.color}
                            >
                                {tab.count}
                            </Label>
                        }
                    />
                ))}
            </Tabs>{' '}
            <TestResultsDetailTableToolbar
                filters={filters}
                lastClearedMs={lastClearedMs}
                //
                downloadExport={downloadExport}
                filterStepName={(value: string) => setFilters({ ...filters, stepName: value })}
                filterCleanedData={(value: boolean) => setFilters({ ...filters, cleanedData: value })}
            />
            <TestResultsDetailTableFiltersResult
                filters={filters}
                onFilters={(updatedFilters: HistoryTableFilter) => setFilters(updatedFilters)}
                sx={{ p: 2.5, pt: 0 }}
                onResetFilters={() => {
                    setFilters(defaultFilters);
                    setLastClearedMs(Date.now());
                }}
            />
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <Scrollbar>
                    <Table size={table.dense ? 'small' : 'medium'} sx={{ minwidth: 800 }}>
                        <TableHead sx={{ minwidth: 800 }}>
                            <TableRow>
                                {TABLE_HEAD.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={(headCell.align as any) || 'left'}
                                        sortDirection={table.orderBy === headCell.id ? table.order : false}
                                    >
                                        <TableSortLabel
                                            hideSortIcon
                                            active={table.orderBy === headCell.id}
                                            direction={table.orderBy === headCell.id ? table.order : 'asc'}
                                            onClick={() => table.onSort(headCell.id)}
                                        >
                                            {headCell.label}

                                            {table.orderBy === headCell.id ? (
                                                <Box sx={{ ...visuallyHidden }}>
                                                    {table.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((row) => (
                                <TestResultDetailTableRow
                                    key={row.id}
                                    metaDefinition={metaDefinitions.find((x) => x.stepName === row.stepName)}
                                    upsertMetaFlagClicked={(metaDefinition?: MetaDefinitionDto) =>
                                        typeof row.stepName === 'string'
                                            ? upsertMetaData(row.stepName, metaDefinition)
                                            : undefined
                                    }
                                    row={row}
                                    filterStepName={() => {
                                        if (!row.stepName) return;
                                        setFilters({
                                            ...filters,
                                            filteredStepNames: [...filters.filteredStepNames, row.stepName],
                                        });
                                    }}
                                />
                            ))}

                            <TableNoData notFound={notFound} />
                        </TableBody>
                    </Table>
                </Scrollbar>
            </TableContainer>
        </Card>
    );
}
