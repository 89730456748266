import { useEffect, useState } from 'react';
import { backendApiResource$ } from 'src/resources/backend-api.resource';
import { MetaDefinitionApi, MetaDefinitionDto, UutResultDto } from '@7c7/backend-api-client';
import { useForm } from 'react-hook-form';
// @mui
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { LinearProgress } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// routes
import { paths } from 'src/routes/paths';
// components
import { useParams } from 'src/routes/hook';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import TestResultDetailsHistory from '../detail/history';
import TestResultDetailsInfo from '../detail/info';
import TestResultDetailsTestHistory from '../detail/test-history';
//

// ----------------------------------------------------------------------
type MetaTagForm = {
    metaTag: string;
};

export default function TestResultDetailView() {
    const [uutResult, setUutResult] = useState<UutResultDto>();
    const [uutResultHistory, setUUtResultHistory] = useState<UutResultDto[]>([]);
    const settings = useSettingsContext();
    const [open, setOpen] = useState(false);
    const [stepName, setStepName] = useState<string>('to replace');
    const [reloader, setReloader] = useState(Date.now());
    const [metaDefinitions, setMetaDefinitions] = useState<MetaDefinitionDto[]>([]);
    const [metaDefinition, setMetaDefinition] = useState<MetaDefinitionDto>();

    const params = useParams();

    const { register, getValues, setValue } = useForm<MetaTagForm>({ values: { metaTag: '' } });
    const { id } = params;

    const upsertMetaDefinition = async (metaTag: string) => {
        if (!metaTag || !uutResult?.partNumber) {
            alert('invalid metatag value');
            return;
        }
        const { metaDefinitionApi } = await backendApiResource$;
        try {
            await metaDefinitionApi.metaDefinitionControllerSaveMetaDefinition(
                {
                    flagName: metaTag,
                    stepName,
                    partNumber: uutResult.partNumber,
                },
                uutResult.id,
            );
            setReloader(Date.now());
            setOpen(false);
        } catch (err) {
            console.log(err);
            alert('failed to add meta tag');
        }
    };

    const deleteMetaDefinition = async () => {
        const { metaDefinitionApi } = await backendApiResource$;
        if (!metaDefinition) return;
        try {
            await metaDefinitionApi.metaDefinitionControllerDeleteMetaDefinition(metaDefinition.id, uutResult?.id);
            setReloader(Date.now());
            setOpen(false);
        } catch (err) {
            console.log(err);
            alert('failed to add meta tag');
        }
    };

    useEffect(() => {
        (async () => {
            if (id === undefined) return;
            setUutResult(undefined);
            const { uutResultApi } = await backendApiResource$;
            const fetchedUutResult = (await uutResultApi.uutResultControllerGetUutResult(parseInt(id, 10))).data;
            setUutResult(fetchedUutResult);

            if (!fetchedUutResult?.uutSerialNumber) {
                setUUtResultHistory([fetchedUutResult]);
                return;
            }
            const fetchedHistory = (
                await uutResultApi.uutResultControllerListUutResults(
                    { serialNumber: fetchedUutResult?.uutSerialNumber },
                    -1,
                    0,
                )
            ).data;
            setUUtResultHistory(fetchedHistory);
        })();
    }, [id]);

    useEffect(() => {
        (async () => {
            const { metaDefinitionApi } = await backendApiResource$;
            if (!uutResult?.partNumber) return;
            const list: MetaDefinitionDto[] = (
                await metaDefinitionApi.metaDefinitionControllerGetMetaDefinitionList(uutResult?.partNumber)
            ).data;
            setMetaDefinitions(list);
        })();
    }, [uutResult, reloader]);

    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <CustomBreadcrumbs
                heading={`${uutResult?.uutSerialNumber ?? ''}`}
                links={[
                    {
                        name: 'Test Results',
                        href: paths.ate.test_results.root,
                    },
                    { name: id },
                ]}
                sx={{ mb: { xs: 3, md: 5 } }}
            />

            {uutResult !== undefined ? (
                <Grid container spacing={3} direction={{ xs: 'column-reverse', md: 'row' }}>
                    <Grid xs={12} md={9}>
                        <TestResultDetailsHistory
                            uutResult={uutResult}
                            metaDefinitions={metaDefinitions}
                            upsertMetaData={(metaStepName: string, toUpdateMetaDefinition?: MetaDefinitionDto) => {
                                setStepName(metaStepName);
                                setMetaDefinition(toUpdateMetaDefinition);
                                setValue('metaTag', toUpdateMetaDefinition?.flagName ?? '');
                                setOpen(true);
                            }}
                        />
                    </Grid>
                    <Grid xs={12} md={3}>
                        <Stack spacing={3} direction={{ xs: 'column' }}>
                            <TestResultDetailsInfo uutResult={uutResult} />
                            <TestResultDetailsTestHistory uutResults={uutResultHistory} />
                        </Stack>
                    </Grid>
                </Grid>
            ) : (
                <LinearProgress />
            )}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    Meta Tag - {uutResult?.partNumber} - {stepName}
                </DialogTitle>
                <DialogContent>
                    <form>
                        <TextField
                            autoFocus
                            margin='dense'
                            id='meta_tag'
                            label='Meta Tag'
                            type='text'
                            fullWidth
                            variant='standard'
                            {...register('metaTag')}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    {metaDefinition ? (
                        <Button onClick={() => deleteMetaDefinition()} color='error' variant='outlined'>
                            Delete
                        </Button>
                    ) : undefined}
                    <Button
                        onClick={() => upsertMetaDefinition(getValues('metaTag'))}
                        color='success'
                        variant='contained'
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
